export function Skills() {
  return (
    <div className="skills" id="skills">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
        />
      </svg>
      <h2>skills</h2>
      <div className="skills-sections">
        <p>languages:</p>
        <p>html</p>
        <p>css</p>
        <p>javascript</p>
        <p>java</p>
      </div>
      <div className="skills-sections">
        <p>dev-tools:</p>
        <p>vs-code</p>
        <p>git</p>
        <p>github</p>
      </div>
      <div className="skills-sections">
        <p>frameworks/libraries:</p>
        <p>react</p>
      </div>
    </div>
  );
}
