export function SocialMedia() {
  return (
    <div className="social-media">
      <a href="https://github.com/SirAzako" target="_blank" rel="noreferrer">
        <img src="github-sign.svg" alt="github logo" />
      </a>
      <a
        href="https://www.linkedin.com/in/phad/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="linkedin-logo.svg" alt="linkedin logo" />
      </a>
    </div>
  );
}
