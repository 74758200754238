import { Project } from "./Project";

const projects = [
  {
    title: "wordle",
    description:
      "Explore the classic Wordle game with a Greek twist! " +
      "This version incorporates the OpenOffice Greek dictionary, adding an educational dimension to the familiar gameplay. " +
      "The sleek design, powered by HTML, CSS, and JavaScript, ensures a seamless and responsive experience on any device. " +
      "Test your Greek vocabulary, receive real-time feedback, and enjoy a blend of education and entertainment in this compact and engaging Wordle variant.",
    link: "https://wordle-greek.netlify.app/",
  },
  // {
  //   title: "weather-app",
  //   description:
  //     "Embark on a seamless weather exploration with this app, designed using HTML, CSS, and JavaScript. Powered by a robust weather API, it provides real-time and accurate weather updates. " +
  //     "The sleek interface adapts effortlessly to various devices, offering an engaging and interactive experience. " +
  //     "Explore hyper-localized weather data, detailed forecasts, and visually pleasing graphics for an effortless and enjoyable weather journey.",
  //   link: "#",
  // },
  {
    title: "currency-converter",
    description:
      "Discover the world of seamless currency conversion with this React-powered app, integrated with a reliable API. " +
      "Effortlessly switch between currencies, receive real-time exchange rates, and experience a user-friendly interface that simplifies international transactions. " +
      "The app adapts smoothly to your needs, providing a quick and efficient way to stay updated on currency values. Enjoy the convenience of a powerful currency converter at your fingertips.",
    link: "https://phad-currency-converter.netlify.app/",
  },
  {
    title: "todo-app",
    description:
      "Effortlessly manage your tasks with this React-powered ToDo app. Add, mark as done, or remove tasks with ease. " +
      "Track your progress as the app dynamically calculates completed and pending tasks, offering a straightforward and efficient way to stay organized. " +
      "Simplify your to-do list management and stay productive with this intuitive and responsive ToDo app.",
    link: "https://phad-todo.netlify.app/",
  },
  {
    title: "hangman",
    description:
      "Experience the timeless fun of Hangman with this React-powered rendition. " +
      "Utilizing an API to fetch random English words, the game challenges players to guess the hidden word before running out of attempts. " +
      "Immerse yourself in the nostalgic gameplay, test your vocabulary, and enjoy the thrill of unraveling words letter by letter. " +
      "With its intuitive design and dynamic features, this Hangman game promises endless entertainment for players of all ages.",
    link: "https://phad-hangman.netlify.app/",
  },
  {
    title: "valorant-quiz",
    description:
      "Put your Valorant knowledge to the test with this interactive quiz game built using React! " +
      "Answer questions about agents, maps, weapons, and more as you strive to prove your expertise in the popular tactical shooter. " +
      "With multiple levels of difficulty and a variety of questions, this game is perfect for both casual players and hardcore fans alike. " +
      "Dive into the world of Valorant and see how well you really know the game!",
    link: "https://valorant-quiz.netlify.app/",
  },
];

export function Projects() {
  return (
    <div className="projects-section">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
        />
      </svg>
      <h2>projects</h2>
      <div className="projects" id="projects">
        {projects.map((project) => (
          <Project
            title={project.title}
            description={project.description}
            link={project.link}
            key={project.title}
          />
        ))}
      </div>
    </div>
  );
}
