import { Navbar } from "./Navbar";
import { Hero } from "./Hero";
import { About } from "./About";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Contact } from "./Contact";

// TODO fix contact navigation

export default function App() {
  console.log("WELCOME TO MY PORTFOLIO");
  return (
    <div className="app">
      <Navbar />
      <Hero id="home" />
      <About />
      <Skills />
      <Projects id="projects" />
      <Contact /> {/* FIXME: navigation to contact - when form submited */}
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <div className="footer">
      <p>© 2024 Paraskevas Hadjigeorgiou. All rights reserved.</p>
    </div>
  );
}
