import { ReactTyped } from "react-typed";

export function About() {
  return (
    <div className="about" id="about">
      <h2>Hello, I'm </h2>
      <h2>
        <span className="green-text">
          <ReactTyped
            strings={["Azako", "Paraskevas Hadjigeorgiou"]}
            typeSpeed={150}
            backSpeed={20}
            loop
          />
        </span>
      </h2>
      <p>
        A front-end development enthusiast currently navigating the vibrant
        realm of code. For the past two years, I've been on a journey honing my
        skills and cultivating a passion for crafting intuitive and visually
        appealing user experiences. Currently a student at Athens University of
        Economics, I'm merging my academic background with practical skills,
        ready to transform ideas into code and elevate web experiences.
        Constantly learning and growing, I'm excited to contribute to the
        dynamic world of front-end development.
      </p>
    </div>
  );
}
