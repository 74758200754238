export function Project({ title, description, link }) {
  return (
    <div className="project">
      <h3>{title}</h3>
      <hr></hr>
      <p>{description}</p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        Check it
      </a>
    </div>
  );
}
