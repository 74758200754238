import { useState } from "react";
import { Link } from "react-scroll";

export function Navbar() {
  const [colorNav, setColorNav] = useState(false);

  function changeNavColor() {
    if (window.scrollY >= 90) {
      setColorNav(true);
    } else {
      setColorNav(false);
    }
  }

  window.addEventListener("scroll", changeNavColor);

  return (
    <ul className={colorNav ? "navbar-background navbar" : "navbar"}>
      <li>
        <Link
          className="link"
          to="home"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          home
        </Link>
      </li>
      <li>
        <Link
          className="link"
          to="about"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          about
        </Link>
      </li>
      <li>
        <Link
          className="link"
          to="skills"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          skills
        </Link>
      </li>
      <li>
        <Link
          className="link"
          to="projects"
          spy={true}
          smooth={true}
          offset={-230}
          duration={500}
        >
          projects
        </Link>
      </li>
      <li>
        <Link
          className="link"
          to="contact"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          contact
        </Link>
      </li>
    </ul>
  );
}
