import { useState } from "react";
import { SocialMedia } from "./SocialMedia";

export function Hero() {
  const [isRocketActive, setIsRocketActive] = useState(false);

  function handleRocket() {
    if (isRocketActive) return;
    setIsRocketActive((isRocketActive) => (isRocketActive = !isRocketActive));
    setTimeout(
      () =>
        setIsRocketActive(
          (isRocketActive) => (isRocketActive = !isRocketActive)
        ),
      8000
    );
  }

  return (
    <div className="hero" id="home">
      <div className="hero-header">
        <h1>
          front<span className="green-text">end</span> developer{" "}
        </h1>
        <span
          className={isRocketActive ? "rocket-animation rocket" : "rocket"}
          onClick={handleRocket}
        >
          🚀
        </span>
      </div>
      <p>translating ideas into code for a pixel-perfect world</p>
      <img src="emoji.png" alt="emoji of paraskevas" />
      <SocialMedia />
    </div>
  );
}
